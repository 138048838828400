<template>
    <div class="toggleBtn" :class="{hide:hide}" @click="toggle()" style="pointer-events:auto;display: inline-block;cursor:pointer;"><i :class="iconClass"></i></div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        hide: false
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      key() {
        return this.config.key
      },
      type() {
        return this.config.type
      },
      iconClass() {
        return this.config.iconClass || ''
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
        }
      })
      var c = this.getFunc({ name: 'getContainerConfig' })(this.key)
      this.hide = c.hide || false
    },
    destroyed() {
    },
    methods: {
      toggle() {
        var hide = this.hide ? null : this.type
        this.hide = !this.hide
        this.getFunc({ name: 'updateContainerConfig' })({ key: this.key, config: { hide } })
      }
    },
  }
</script>
<style scoped>
  .toggleBtn {
    transform: rotate(0);
    transition: all .5s;
  }

    .toggleBtn.hide {
      transform: rotate(180deg);
    }
</style>
